import { SignedInUser } from "@revelate/types";
import { format } from "date-fns";
import { DateRange } from "react-day-picker";

const apiUrl = import.meta.env.VITE_API_URL;

export const getProviderSyncUrl = (
  provider: string,
  companyId: number,
  providerId?: string
) => {
  return (
    import.meta.env.VITE_API_URL +
    `/providers/${provider}/deals/${companyId}${
      providerId ? `/${providerId}` : ""
    }`
  );
};

export const getDateRangeSyncUrl = (
  company_id: number,
  dateRange: DateRange
) => {
  const { from: fromStr, to: toStr } = dateRange;

  const from = fromStr ? format(fromStr, "yyyy-MM-dd") : "";
  const to = toStr ? format(toStr, "yyyy-MM-dd") : "";
  return (
    import.meta.env.VITE_API_URL +
    `/companies/${company_id}/sync?from=${from}&to=${to}`
  );
};

export const getMagicLinkUrl = (email: string) => {
  return import.meta.env.VITE_API_URL + `/users/magiclink/${email}`;
};

export const getAPI = async (path: string, searchParams = "", cache = true) => {
  const result = await fetch(`${apiUrl}/${path}${searchParams}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + import.meta.env.VITE_API_KEY,
    },
    cache: cache ? "default" : "no-cache",
  });
  if (!result.ok) {
    console.log("Error with API request: ", await result.text());
    return {};
  }
  return await result.json();
};

export const postAPI = async (
  path: string,
  body: string,
  cache = true,
  onError?: () => void
) => {
  const result = await fetch(`${apiUrl}${path}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + import.meta.env.VITE_API_KEY,
    },
    body,
    cache: cache ? "default" : "no-cache",
  });

  if (!result.ok) {
    console.log("Error with API request: ", await result.text());
    onError && onError();
    return null;
  }
  return await result.json();
};

export const patchAPI = async (
  path: string,
  body: string,
  cache = true,
  onError?: () => void
) => {
  const result = await fetch(`${apiUrl}${path}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + import.meta.env.VITE_API_KEY,
    },
    body,
    cache: cache ? "default" : "no-cache",
  });

  if (!result.ok) {
    console.log("Error with API request: ", await result.text());
    onError && onError();
    return null;
  }
  return await result.json();
};

export const getCalcUrl = (
  currentUser: SignedInUser | null,
  dateRange?: DateRange,
  userIds?: string[],
  isProjected?: boolean,
  logResult?: boolean
) => {
  return currentUser && dateRange
    ? `calc/commissions/${currentUser.company_id}?${userIds && userIds.length > 0 ? userIds?.map((id) => `userIds=${id}`).join("&") : ""}${
        dateRange.from ? `&from=${format(dateRange?.from, "yyyy-MM-dd")}` : ""
      }${dateRange.to ? `&to=${format(dateRange?.to, "yyyy-MM-dd")}` : ""}${
        isProjected ? "&isProjected=true" : ""
      }${logResult ? "&logResult=true" : ""}`
    : null;
};
