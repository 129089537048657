"use client";

import * as React from "react";
import { CheckIcon, Circle, HelpCircle, LucideIcon } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { CustomFormItemProps } from "../accelerators-form";
import { CommissionBasedOn } from "@revelate/types";

type Status = {
  value: string;
  label: string;
  icon: LucideIcon;
};

const statuses: Status[] = [
  {
    value: "gt",
    label: "is greater than",
    icon: Circle,
  },
  {
    value: "gte",
    label: "is greater than or equal to",
    icon: Circle,
  },
  {
    value: "lt",
    label: "is less than",
    icon: Circle,
  },
  {
    value: "lte",
    label: "is less than or equal to",
    icon: Circle,
  },
  {
    value: "is",
    label: "is exactly",
    icon: HelpCircle,
  },
  {
    value: "range",
    label: "is in range",
    icon: HelpCircle,
  },
  {
    value: "in",
    label: "is in a list of values",
    icon: Circle,
  },
  {
    value: "not_in",
    label: "is NOT in a list of values",
    icon: Circle,
  },
  {
    value: "always_true",
    label: "is used in sales target calculation",
    icon: Circle,
  },
  {
    value: "current_user",
    label: "is current user being calculated",
    icon: Circle,
  },
  // {
  //   value: "not",
  //   label: "is not",
  //   icon: XCircle,
  // },
  // {
  //   value: "not_range",
  //   label: "is not in range",
  //   icon: XCircle,
  // },
  // {
  //   value: "not_gte",
  //   label: "is less than",
  //   icon: XCircle,
  // },
  // {
  //   value: "not_lte",
  //   label: "is greater than",
  //   icon: XCircle,
  // },
];

const filteredStatuses = (statuses: Status[], commissionBasis: CommissionBasedOn, selectedKey: string) => {
  return statuses.filter((s) => {
    const { value } = s || {};
    const alwaysFiltered = (value !== "always_true" && value !== 'current_user' && value !== "not_in" && value !== "in")
    
    if (selectedKey === "deals_pipeline") return value === "in" || value === "not_in"
    if (selectedKey?.includes("quota") && commissionBasis?.includes('deal_value_below_target')) return (value === 'always_true')
    if (selectedKey === "deals_owner" || selectedKey === "deals_collaborator") return value === 'current_user';

    return alwaysFiltered;
  });
};

type ConditionsProps = CustomFormItemProps & {
  commissionBasis: CommissionBasedOn;
  selectedKey: string;
};

export function ConditionCombobox({
  value,
  onChange,
  commissionBasis,
  selectedKey,
}: ConditionsProps) {
  const [open, setOpen] = React.useState(false);
  const [selectedStatus, setSelectedStatus] = React.useState<
    Status | undefined
  >(statuses.find((c) => c.value === value));

  const handleSelect = (label: string) => {
    const selectedStatus = statuses.find((c) => c.label === label);
    setSelectedStatus(selectedStatus);
    setOpen(false);
    onChange(selectedStatus?.value);
  };

  return (
    <div className="flex items-center space-x-4">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            size="sm"
            className="w-[200px] justify-start"
          >
            {selectedStatus ? (
              <>
                {/* <selectedStatus.icon className="mr-2 h-4 w-4 shrink-0" /> */}
                {selectedStatus.label}
              </>
            ) : (
              <>+ Set condition</>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0" side="right" align="start">
          <Command>
            <CommandInput placeholder="Search conditions..." />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {filteredStatuses(statuses, commissionBasis, selectedKey)
                  .map((status) => (
                    <CommandItem
                      key={status.value}
                      value={status.label}
                      onSelect={(label) => handleSelect(label)}
                    >
                      <span>{status.label}</span>
                      <CheckIcon
                        className={cn(
                          "ml-auto h-4 w-4",
                          status.value === selectedStatus?.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
