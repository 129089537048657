import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components//ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useAppStore } from "@/stores";
import { useAuth } from "@saas-ui/auth";
import useSWR from "swr";
import { changeCompany, getCompanies } from "@/lib/supabase";
import { CheckIcon, CircleUser, Plus, User } from "lucide-react";
import { getFullName, getUserInitials } from "@revelate/utils";
import { NewUserForm } from "./new-user-form";
import { useState, useEffect } from "react";
import { Loader } from "@/components/Loader";
import { TODO } from "@revelate/types";

export function UserNav() {
  const { currentUser, reset: resetStore } = useAppStore((state) => state);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [companySwitcherOpen, setCompanySwitcherOpen] = useState(false);
  const [newUserDialogOpen, setNewUserDialogOpen] = useState(false);

  // Add keyboard shortcut handler
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        if (currentUser?.role === "super_admin") {
          setCompanySwitcherOpen((open) => !open);
        }
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [currentUser?.role]);

  const { data: companies } = useSWR(currentUser, getCompanies, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const auth = useAuth();
  const logOut = () => {
    auth.logOut();
    resetStore();
  };

  if (!currentUser) return <Loader />;
  return (
    <>
      <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            variant="secondary"
            size="icon"
            className="relative h-9 w-9 rounded-full"
          >
            {currentUser?.avatar_url ? (
              <Avatar className="h-9 w-9">
                <AvatarImage
                  src={currentUser?.avatar_url || ""}
                  alt={getFullName(currentUser)}
                />
                <AvatarFallback>{getUserInitials(currentUser)}</AvatarFallback>
              </Avatar>
            ) : (
              <CircleUser className="h-5 w-5" />
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="end" forceMount>
          <DropdownMenuLabel className="font-normal">
            <div className="flex flex-col space-y-1">
              <p className="text-sm font-medium leading-none">
                {getFullName(currentUser)}
              </p>
              <p className="text-xs leading-none text-muted-foreground">
                {currentUser?.email || "Anonymous"}
              </p>
            </div>
          </DropdownMenuLabel>

          {currentUser?.role === "super_admin" && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="cursor-pointer"
                onClick={() => {
                  setCompanySwitcherOpen(true);
                  setDropdownOpen(false);
                }}
              >
                Switch Company
                <DropdownMenuShortcut>⌘K</DropdownMenuShortcut>
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="cursor-pointer"
                key="add-user"
                onClick={() => {
                  setNewUserDialogOpen(true);
                  setDropdownOpen(false);
                }}
              >
                <Plus className="mr-2 h-4 w-4" />
                Add new user
                <DropdownMenuShortcut>
                  <User className="mr-2 h-4 w-4" />
                </DropdownMenuShortcut>
              </DropdownMenuItem>
            </>
          )}
          <DropdownMenuGroup>
            {/* <DropdownMenuItem>
            Profile
            <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
          </DropdownMenuItem> */}
            {/* <DropdownMenuItem>
            Billing
            <DropdownMenuShortcut>⌘B</DropdownMenuShortcut>
          </DropdownMenuItem>
          <DropdownMenuItem>
            Settings
            <DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
          </DropdownMenuItem>
          <DropdownMenuItem>New Team</DropdownMenuItem> */}
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem className="cursor-pointer" onClick={() => logOut()}>
            Log out
            <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {currentUser?.role === "super_admin" && (
        <>
          <Dialog
            open={companySwitcherOpen}
            onOpenChange={setCompanySwitcherOpen}
          >
            <DialogContent className="p-0" style={{ maxWidth: "400px" }}>
              <Command shouldFilter={true}>
                <CommandInput placeholder="Search companies..." autoFocus />
                <CommandList>
                  <CommandEmpty>No companies found.</CommandEmpty>
                  <CommandGroup heading="Companies">
                    {companies?.map((c: TODO) => (
                      <CommandItem
                        key={c.id}
                        onSelect={async () => {
                          await changeCompany(currentUser, c.id);
                          window.location.reload();
                        }}
                        className="flex items-center justify-between"
                      >
                        <div className="flex items-center gap-2">
                          <span>{c.name}</span>
                          <span className="text-xs text-muted-foreground">
                            #{c.id}
                          </span>
                        </div>
                        {currentUser?.company_id === c.id && (
                          <CheckIcon className="h-4 w-4" />
                        )}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            </DialogContent>
          </Dialog>
          <NewUserForm
            companies={companies}
            open={newUserDialogOpen}
            setOpen={setNewUserDialogOpen}
          />
        </>
      )}
    </>
  );
}
