import {
  NestedUser,
  NestedPlan,
  DateRange,
  GroupedCommissions,
  KeyDeal,
  CommissionBasedOn,
  SignedInCompany,
  CalculationDeal,
} from "@revelate/types";
import { criteriaMet } from "./accelerators";
import {
  CommissionStatus,
  CommissionUser,
  NestedCommission,
  NestedCompany,
  Scope,
  TODO,
} from "@revelate/types";
import {
  format,
  startOfDay,
  endOfDay,
} from "date-fns";
import { enUS } from "date-fns/locale";
import { getUserIdsForTeam } from "./teams";
import {
  filterByDealRole,
  getDealsForDateRange,
  getDealValue,
  getDealValueFromCommissions,
} from "./deals";
import {
  capitalizeFirstLetter,
  generateCompositeKey,
  generateUniqueCommissionsKey,
  getCurrencyFormatted,
  getFullName,
  getStartEndDates,
  getStartOfYearToEndOfPreviousTimePeriod,
  shouldCalculate,
} from "@revelate/utils";
import {
  getExchangeRateForCurrencyId,
  getExchangeRateForDeal,
} from "./currencies";
import { OperationTimer, logOperation } from './performance';
import { getHumanReadableConditionName } from "./conditions";

const getCommissionBasedOn = (
  deal: CalculationDeal,
  commission_based_on: string,
  isProjected: boolean = false,
) => {
  let value = 0;

  switch (commission_based_on) {
    case "deal_value":
      value = deal.value || 0;
      break;
    case "onboarding_value":
      value = deal.onboarding_value ? deal.onboarding_value : 0;
      break;
    // case "deal_value_arr":
    //   value = deal.value * 12 || 0;
    //   break;
    case "deal_value_above_target":
      value = 0; // Calculated separately after all deals are processed
      break;
    case "deal_value_below_target":
      value = 0; // Calculated separately after all deals are processed
      break;
    default:
      value = deal.value || 0;
  }

  const projectionMultiplier = isProjected ? deal.likelihood_to_close || 1 : 1;
  return value * projectionMultiplier;
};

const getAboveBelowCommissionBasis = (
  commission_based_on: CommissionBasedOn,
  targetValue: number,
  totalDealValue: number,
  previousTotalDealValue: number,
  isYTD: boolean
) => {
  if (commission_based_on === "deal_value_above_target") {
    const aboveTargetDealValue = isYTD
      ? totalDealValue + previousTotalDealValue
      : totalDealValue;
    const remainingDealValue = isYTD
      ? totalDealValue -
        (targetValue > previousTotalDealValue
          ? targetValue - previousTotalDealValue
          : 0)
      : totalDealValue - targetValue;
    return aboveTargetDealValue > targetValue ? remainingDealValue : 0;
  }
  if (commission_based_on === "deal_value_below_target") {
    const belowTargetValueRemaining = isYTD
      ? targetValue - previousTotalDealValue
      : targetValue;
    if (belowTargetValueRemaining <= 0) return 0;
    return totalDealValue > belowTargetValueRemaining
      ? belowTargetValueRemaining
      : totalDealValue;
  }
  return 0;
};

function getProviderStats(deals: CalculationDeal[]) {
  return deals.reduce((acc, deal) => {
    const provider = deal.provider || 'unknown';
    if (!acc[provider]) {
      acc[provider] = { count: 0, totalValue: 0 };
    }
    acc[provider].count++;
    acc[provider].totalValue += deal.value || 0;
    return acc;
  }, {} as Record<string, { count: number; totalValue: number }>);
}

async function precomputeProviderExchangeRates(company: NestedCompany, deals: CalculationDeal[]) {
  const rateCache = new Map<string, number>();
  const batchSize = 50;
  
  // Process exchange rates in batches
  for (let i = 0; i < deals.length; i += batchSize) {
    const batch = deals.slice(i, i + batchSize);
    await Promise.all(
      batch.map(async (deal) => {
        const key = `${deal.provider}:${deal.currency_id || company.default_currency_id}`;
        if (!rateCache.has(key)) {
          rateCache.set(key, getExchangeRateForDeal(company, deal));
        }
      })
    );
  }
  
  return rateCache;
}

// Batch process deals in parallel for better performance
async function processDealBatch(deals: CalculationDeal[], params: {
  company: NestedCompany,
  user: NestedUser,
  plan: NestedPlan,
  isProjected: boolean,
  commission_based_on: string,
  rateCache: Map<string, number>
}) {
  const { company, user, plan, isProjected, commission_based_on, rateCache } = params;
  const results = await Promise.all(
    deals.map(async (deal) => {
      const key = `${deal.provider}:${deal.currency_id || company.default_currency_id}`;
      const exchangeRate = rateCache.get(key) || 1;
      const value = getCommissionBasedOn(deal, commission_based_on, isProjected);
      return {
        deal,
        value: value * exchangeRate
      };
    })
  );
  return results;
}

export const calculateCommissionsForPlan = async ({
  calculationDate,
  dateRange,
  company,
  user,
  plan,
  isProjected = false,
  logs,
  isDev
}: {
  calculationDate: Date;
  dateRange: DateRange;
  company: NestedCompany;
  user: NestedUser;
  plan: NestedPlan;
  isProjected: boolean;
  logs: string[];
  isDev: boolean;
}): Promise<NestedCommission[]> => {
  const planTimer = new OperationTimer('calculateCommissionsForPlan', { 
    userId: user.id, 
    planId: plan?.id,
    providers: plan?.providers || [],
    dealTypes: plan?.deal_types || []
  });

  if (!user || !plan || !dateRange) return [];

  const { time_period, deal_types, providers, accelerators, deal_role } =
    plan || {};

  // Check if we should use this plan for the given date
  if (!shouldCalculate(calculationDate, time_period) || !time_period) {
    // logs.push(
    //   `   ->   Plan ${plan.id} is not active for ${format(calculationDate, "MMMM yyyy", { locale: enUS })}, skipping plan processing.\n`
    // );
    return [];
  }

  const dealsTimer = new OperationTimer('deals-filtering', { 
    userId: user.id, 
    dealRole: deal_role 
  });
  
  // Get all deals for this user's role and precompute exchange rates
  const deals = filterByDealRole(company.deals || [], deal_role, user.id);
  const rateCache = await precomputeProviderExchangeRates(company, deals);
  
  // Filter the deals for the given time period once
  const startEndDateRange = getStartEndDates(dateRange, calculationDate, time_period)
  const dealsForDateRange: CalculationDeal[] = getDealsForDateRange({
    deals,
    dateRange: startEndDateRange,
    dealTypes: deal_types,
    providers: providers || undefined,
    dealRole: deal_role,
    userId: user.id,
    isProjected,
  });

  // Calculate provider stats once
  const providerStats = getProviderStats(deals);
  const filteredProviderStats = getProviderStats(dealsForDateRange);

  logOperation(dealsTimer.end(), logs, isDev);

  const commissions: NestedCommission[] = [];
  const dealsPassed: CalculationDeal[] = [];

  // User can now have multiple accelerators per plan that kick in
  // So we need to loop through all of them and check the conditions for each one
  if (!accelerators || accelerators.length === 0) return [];

  // Order accelerators by highest rate
  const sortedAccelerators = accelerators.sort((a, b) => b.rate - a.rate);

  const acceleratorsTimer = new OperationTimer('accelerators-processing', {
    userId: user.id,
    numAccelerators: sortedAccelerators.length,
    totalDeals: dealsForDateRange.length,
    providerStats: filteredProviderStats
  });

  // Precompute exchange rates for all deals once
  const exchangeRates = await precomputeProviderExchangeRates(company, dealsForDateRange);

  // Enhanced cache that includes commission_based_on in the key
  const dealValueCache = new Map<string, number>();
  
  // Pre-calculate all possible deal values
  const uniqueCommissionBasedOn = new Set(['', ...(sortedAccelerators.map(a => a.commission_based_on || ''))]);
  
  // Process deals in batches for value calculation
  const batchSize = 50;
  for (let i = 0; i < dealsForDateRange.length; i += batchSize) {
    const batch = dealsForDateRange.slice(i, i + batchSize);
    await Promise.all(
      batch.map(async (deal) => {
        const key = `${deal.provider}:${deal.currency_id || company.default_currency_id}`;
        const rate = exchangeRates.get(key) || 1; // Default to 1 if no rate found
        
        // Cache all possible commission_based_on values
        uniqueCommissionBasedOn.forEach(commission_based_on => {
          const value = getCommissionBasedOn(deal, commission_based_on, isProjected);
          const cacheKey = `${deal.id}:${commission_based_on}`;
          dealValueCache.set(cacheKey, value * rate);
        });
      })
    );
  }

  // Add header to logs
  const dealsFound = dealsForDateRange.length;

  logs.push(
    "=".repeat(75),
    // `Commission Calculation for ${getFullName(user)} (${user.email})`,
    `${capitalizeFirstLetter(plan.time_period || "month")}ly Plan: ${plan.name} (Plan ID: ${plan.id})`,
    `Date range to find deals: ${startEndDateRange?.from?.toLocaleDateString()} - ${startEndDateRange?.to?.toLocaleDateString()}`,
    `Found ${dealsFound} deals from ${plan?.providers?.join(' and ') || 'all providers'} for ${getFullName(user)} as ${deal_role}`,
    "=".repeat(75),
    ""
  );

  const resultLogger = new OperationTimer('Plan Summary', {
    planName: plan.name || 'Unnamed Plan',
    userName: getFullName(user),
    period: format(calculationDate, "MMMM yyyy", { locale: enUS })
  });

  
  if (dealsFound > 0) {
    if (dealsFound <= 10) {
      logs.push("Deals found:");
      dealsForDateRange.forEach(deal => {
        const amount = getCurrencyFormatted(getDealValue(company, deal), deal.currency_code);
        logs.push(`  - ${deal.name} (${format(new Date(deal.closed_at || deal.created_at), "MMM d, yyyy")}): ${amount}`);
      });
    }
  }

  // Helper function to get cached deal value
  const getOptimizedCommissionBasis = (
    deal: CalculationDeal,
    commission_based_on: string = "",
    logs: string[] = []
  ) => {
    const cacheKey = `${deal.id}:${commission_based_on}`;
    const cachedValue = dealValueCache.get(cacheKey);
    
    if (cachedValue !== undefined) {
      return cachedValue;
    }

    // Fallback for any uncached scenarios
    const value = getCommissionBasedOn(deal, commission_based_on, isProjected);
    const key = `${deal.provider}:${deal.currency_id || company.default_currency_id}`;
    const rate = exchangeRates.get(key) || getExchangeRateForDeal(company, deal);
    const finalValue = value * rate;
    
    // Cache the result for future use
    dealValueCache.set(cacheKey, finalValue);
    
    logs.push(
      `Deal ${deal.name} value: ${value}, rate: ${rate}, final: ${finalValue}`
    );
    return finalValue;
  };

  // Group accelerators by their conditions to batch process similar ones
  const acceleratorsByCondition = new Map<string, typeof sortedAccelerators>();
  sortedAccelerators.forEach(accelerator => {
    const conditionKey = JSON.stringify(accelerator.conditions || []);
    const existing = acceleratorsByCondition.get(conditionKey) || [];
    existing.push(accelerator);
    acceleratorsByCondition.set(conditionKey, existing);
  });

  // Process accelerators grouped by similar conditions
  for (const [_, acceleratorGroup] of acceleratorsByCondition) {
    const criteriaLogger = new OperationTimer('criteria-met');
    
    logs.push(
      `\n`,
      "=".repeat(50),
      `${acceleratorGroup?.map((a) => `${a.rate * 100}%`).join(" and ")} commission rate for accelerator(s) ${acceleratorGroup?.map((a) => a.id).join(" and ")}`,
      "=".repeat(50),
    );
    
    if (acceleratorGroup[0].conditions && acceleratorGroup[0].conditions.length > 0) {
      logs.push(`Conditions to meet:`);
      acceleratorGroup[0].conditions.filter((c) => !c?.key?.includes('quota')).forEach(condition => {
        logs.push(`  -  ${condition.key} ${getHumanReadableConditionName(condition)} (Condition ID: ${condition.id})`);
      });
    }

    // Check conditions once for the group
    const {
      success,
      successQuotaTargetValue,
      successQuotaIsYTD,
      filteredDeals,
    } = await criteriaMet({
      calculationDate,
      dateRange,
      company,
      user,
      deals,
      plan,
      accelerator: acceleratorGroup[0], // Use first accelerator's conditions
      dealsForDateRange,
      logs,
    });
    logOperation(criteriaLogger.end(), logs, isDev);

    // logs.push(`\nResults:`);
    // logs.push(`  -  Success: ${success}`);
    // logs.push(`  -  SuccessQuotaTargetValue: ${successQuotaTargetValue}`);
    // logs.push(`  -  SuccessQuotaIsYTD: ${successQuotaIsYTD}`);
    // if (success) logs.push(`  -  Deals subject for commission: ${filteredDeals && filteredDeals.length > 0 ? filteredDeals?.map(deal => deal.name) : '(none)'}`);

    if (!success) {
      continue;
    }

    const calculationTimer = new OperationTimer('commission-calculation', {
      userId: user.id,
      numDeals: filteredDeals.length
    });

    const baseCommission: NestedCommission = {
      company_id: company.id,
      month: Number(format(calculationDate, "MM")),
      year: Number(format(calculationDate, "yyyy")),
      user_id: user.id,
      plan_id: plan.id,
      accelerator: {
        id: acceleratorGroup[0].id,
        name: acceleratorGroup[0].name,
        commission_based_on: acceleratorGroup[0].commission_based_on,
        fixed_value_amount: acceleratorGroup[0].fixed_value_amount,
      },
      accelerator_id: acceleratorGroup[0].id,
      rate: acceleratorGroup[0].rate,
      plan: {
        id: plan.id,
        name: plan.name,
        time_period: plan.time_period,
        deal_types: plan.deal_types,
        deal_role: plan.deal_role,
      },
      created_at: new Date() as TODO,
      updated_at: new Date() as TODO,
      status: isProjected ? "projected" : "unapproved",
      deal_id: null,
      deal: undefined,
      commission_basis: 0,
      amount: 0,
      deals_key: generateCompositeKey(filteredDeals?.map(deal => deal.id) || []),
    };

    const totalDealValue = filteredDeals.reduce((sum, deal) => sum + (dealValueCache.get(`${deal.id}:`) || 0), 0);

    if (acceleratorGroup[0].commission_based_on === "fixed_value" && acceleratorGroup[0].fixed_value_amount) {
      const commission_basis = acceleratorGroup[0].fixed_value_currency_id
        ? getExchangeRateForCurrencyId(company, acceleratorGroup[0].fixed_value_currency_id) *
          acceleratorGroup[0].fixed_value_amount
        : acceleratorGroup[0].fixed_value_amount;
      const amount = commission_basis * acceleratorGroup[0].rate;
      const commission: NestedCommission = {
        ...baseCommission,
        commission_basis,
        amount,
        deals_count: filteredDeals?.length || 0,
        value_actual: totalDealValue,
        value_target: successQuotaTargetValue,
        // deal_id: deal ? deal.id : null,
        // deal: deal || undefined,
      };
      if (amount > 0) {
        commissions.push(commission);
        logs.push(
          "=".repeat(75),
          `SUCCESS: Matched ${acceleratorGroup[0].name} (Accelerator ID: ${acceleratorGroup[0].id})`,
          "=".repeat(75),
          `  -  Based on: ${acceleratorGroup[0].commission_based_on} ${
            successQuotaTargetValue
              ? `(Actual value this period: ${totalDealValue}, Target value: ${successQuotaTargetValue})`
              : ""
          }`,
          `  -  Deals for commission: ${filteredDeals.length} (${filteredDeals.length <= 10 ? filteredDeals.map((deal) => `${deal.name}: ${getCurrencyFormatted(getDealValue(company, deal), deal.currency_code)}`).join(", ") : filteredDeals.length > 10 ? "too many deals to list" : ""})`,
          `  -  Total commission basis: ${getCurrencyFormatted(commissions
            .reduce((acc, commission) => acc + commission.commission_basis, 0)
            , company?.default_currency?.code)}`,
          `  -  Rate: ${acceleratorGroup[0].rate * 100}%`,
          "=".repeat(75),
          `Amount to be paid: ${getCurrencyFormatted(commissions.reduce((acc, commission) => acc + commission.amount, 0), company?.default_currency?.code)}`,
          "=".repeat(75),
        );
      }
    } else if (
      (acceleratorGroup[0].commission_based_on === "deal_value_above_target" ||
        acceleratorGroup[0].commission_based_on === "deal_value_below_target") &&
      successQuotaTargetValue
    ) {
      const dealsForPreviousDateRange: CalculationDeal[] = getDealsForDateRange({
        deals,
        dateRange: getStartOfYearToEndOfPreviousTimePeriod(
          calculationDate,
          time_period
        ),
        dealTypes: deal_types,
        providers: providers || undefined,
        dealRole: deal_role,
        userId: user.id,
        isProjected,
      });

      const previousTotalDealValue = dealsForPreviousDateRange
        ?.map((deal) => getExchangeRateForDeal(company, deal) * deal.value)
        .reduce((acc, value) => acc + value, 0);

      const targetValue = successQuotaTargetValue;

      const commission_basis = getAboveBelowCommissionBasis(
        acceleratorGroup[0].commission_based_on,
        targetValue,
        totalDealValue,
        previousTotalDealValue,
        !!successQuotaIsYTD
      );

      const aboveLogger = new OperationTimer('Above/Below Calculation');
      


      logOperation(aboveLogger.end(), logs, isDev);

      const amount = commission_basis * acceleratorGroup[0].rate;
      const commission: NestedCommission = {
        ...baseCommission,
        commission_basis,
        amount,
        deals_count: filteredDeals?.length || 0,
        value_actual: totalDealValue,
        value_target: successQuotaTargetValue,
        // deal_id: deal ? deal.id : null,
        // deal: deal || undefined,
      };

      if (amount > 0) {
        commissions.push(commission);
        logs.push(
          "=".repeat(75),
          `SUCCESS: Matched ${acceleratorGroup[0].name} (Accelerator ID: ${acceleratorGroup[0].id})`,
          "=".repeat(75),         
          `  -  Based on: ${acceleratorGroup[0].commission_based_on}`,
        );

      logs.push(`  -  Target: ${getCurrencyFormatted(targetValue, company?.default_currency?.code)}`);
      logs.push(`  -  Total Attainment Current Period: ${getCurrencyFormatted(totalDealValue, company?.default_currency?.code)}`);

      
      if (successQuotaIsYTD) {
        logs.push(`  -  Total Attainment Previous Period: ${getCurrencyFormatted(previousTotalDealValue, company?.default_currency?.code)}`);
      }

      if (totalDealValue > targetValue) {
        const above = totalDealValue - targetValue;
        logs.push(`  -  Above target by ${getCurrencyFormatted(above, company?.default_currency?.code)}`);
      } else {
        const below = targetValue - totalDealValue;
        logs.push(`  -  Below target by ${getCurrencyFormatted(below, company?.default_currency?.code)}`);
      }


        logs.push(
          `  -  Deals for commission: ${filteredDeals.length} (${filteredDeals.map((deal) => `${deal.name}: ${getCurrencyFormatted(getDealValue(company, deal), company?.default_currency?.code)}`).join(", ")}`,
          `  -  Total commission basis: ${getCurrencyFormatted(commissions
            .reduce((acc, commission) => acc + commission.commission_basis, 0)
            , company?.default_currency?.code)}`,
          `  -  Rate: ${acceleratorGroup[0].rate * 100}%`,
          "=".repeat(75),
          `Amount to be paid: ${getCurrencyFormatted(commissions.reduce((acc, commission) => acc + commission.amount, 0), company?.default_currency?.code)}`,
          "=".repeat(75),
        );
      }
    } else {
      const fixedLogger = new OperationTimer('Fixed Value Calculation');
      
      logs.push(`\nCalculating Fixed Value commission:`);
      const fixedAmount = acceleratorGroup[0].fixed_value_amount || 0;
      logs.push(`Fixed amount: ${getCurrencyFormatted(fixedAmount, company?.default_currency?.code)}`);
      if (acceleratorGroup[0].conditions && acceleratorGroup[0].conditions.length > 0) {
        logs.push(`Conditions required:`);
        acceleratorGroup[0].conditions.forEach(condition => {
          logs.push(`  - ${condition.key} ${condition.operator} ${condition.value}`);
        });
      }

      logOperation(fixedLogger.end(), logs, isDev);

      // Process deals in parallel batches for better memory management
      const processDealBatch = async (deals: CalculationDeal[]) => {
        const batchSize = 100;
        const batches: CalculationDeal[][] = [];
        
        // Split deals into batches
        for (let i = 0; i < deals.length; i += batchSize) {
          batches.push(deals.slice(i, i + batchSize));
        }
        
        // Process all batches in parallel
        const batchResults = await Promise.all(
          batches.map(async (batch) => {
            // Process each deal within the batch in parallel
            const dealResults = await Promise.all(
              batch.map(async (deal) => {
                const commission_basis = getOptimizedCommissionBasis(
                  deal,
                  acceleratorGroup[0].commission_based_on,
                  logs
                );
                const amount = commission_basis * acceleratorGroup[0].rate;
                return {
                  ...baseCommission,
                  deal_id: deal.id,
                  deals_count: 1,
                  deals_key: generateCompositeKey([deal.id]),
                  deal: {
                    id: deal.id,
                    name: deal.name,
                    provider: deal.provider,
                    provider_id: deal.provider_id,
                    is_won: deal.is_won,
                    is_lost: deal.is_lost,
                    closed_at: deal.closed_at,
                    value: deal.value,
                  },
                  commission_basis,
                  amount,
                };
              })
            );
            
            dealsPassed.push(...batch);
            return dealResults;
          })
        );
        
        // Flatten all results into a single array
        return batchResults.flat();
      };

      const results = await processDealBatch(filteredDeals);
      commissions.push(...results);
      
      logs.push(
        "=".repeat(75),
        `SUCCESS: Matched ${acceleratorGroup[0].name} (Accelerator ID: ${acceleratorGroup[0].id})`,
        "=".repeat(75),
        `  -  Based on: ${acceleratorGroup[0].commission_based_on} ${
          successQuotaTargetValue
            ? `(Actual value this period: ${totalDealValue}, Target value: ${successQuotaTargetValue})`
            : ""
        }`,
        `  -  Deals for commission: ${filteredDeals.length} (${filteredDeals.map((deal) => `${deal.name}: ${getCurrencyFormatted(getDealValue(company, deal), company?.default_currency?.code)}`).join(", ")}`,
        `  -  Total commission basis: ${getCurrencyFormatted(commissions
          .reduce((acc, commission) => acc + commission.commission_basis, 0)
          , company?.default_currency?.code)}`,
        `  -  Rate: ${acceleratorGroup[0].rate * 100}%`,
        "=".repeat(75),
        `Amount to be paid: ${getCurrencyFormatted(commissions.reduce((acc, commission) => acc + commission.amount, 0), company?.default_currency?.code)}`,
        "=".repeat(75),
      );
    }

    logOperation(calculationTimer.end(), logs, isDev);
  }

  logOperation(acceleratorsTimer.end(), logs, isDev);
  logOperation(resultLogger.end(), logs, isDev);
  logOperation(planTimer.end(), logs, isDev);

  // Add summary to logs
  // logs.push(
  //   "=".repeat(50),
  //   "Final Commission Summary:",
  //   `Total Deals: ${commissions.length}`,
  //   `Total Commission: ${formatCurrency(commissions.reduce((sum, c) => sum + c.amount, 0), 'USD')}`,
  //   "=".repeat(50)
  // );

  return commissions;
};

export const calculateCommissionForUser = async ({
  dateRange,
  company,
  user,
  isProjected,
  logs,
  isDev
}: {
  dateRange: DateRange;
  company: NestedCompany;
  user: NestedUser;
  isProjected: boolean;
  logs: string[];
  isDev: boolean;
}) => {
  const statsTimer = new OperationTimer('initial-provider-stats', { 
    userId: user.id,
    totalDeals: company.deals?.length || 0
  });
  const providerStats = getProviderStats(company.deals || []);
  logOperation(statsTimer.end(), logs, isDev);

  const userTimer = new OperationTimer('calculateCommissionForUser', { 
    userId: user.id, 
    email: user.email,
    numPlans: user.plans?.length || 0,
    totalDeals: company.deals?.length || 0,
    providerStats
  });

  const allCommissions: NestedCommission[] = [];
  const { plans } = user;
  if (!plans || (plans && plans.length === 0)) {
    logOperation(userTimer.end(), logs, isDev);
    return allCommissions;
  }

  const { from, to } = dateRange || {};
  if (!from || !to) {
    logOperation(userTimer.end(), logs, isDev);
    return allCommissions;
  }

  const calculationDate = new Date(from);
  while (calculationDate <= new Date(to)) {
    const filterTimer = new OperationTimer('period-deal-filtering', {
      userId: user.id,
      period: `${format(calculationDate, "yyyy-MM")}`,
      totalDeals: company.deals?.length || 0
    });
    const periodDeals = company.deals?.filter(deal => {
      const dealDate = new Date(deal.closed_at || deal.created_at);
      return dealDate.getMonth() === calculationDate.getMonth() &&
             dealDate.getFullYear() === calculationDate.getFullYear();
    });
    logOperation(filterTimer.end(), logs, isDev);
    
    const periodProviderStats = getProviderStats(periodDeals || []);

    const periodTimer = new OperationTimer('period-processing', {
      userId: user.id,
      period: `${format(calculationDate, "yyyy-MM")}`,
      numDeals: periodDeals?.length || 0,
      providerStats: periodProviderStats
    });

    logs.push(
      `\n\n`,
      "=".repeat(100),
      `${format(calculationDate, "MMMM yyyy", { locale: enUS })}: ${getFullName(user)} (${user.email} - ${user.id})`,
      "=".repeat(100),
      `\n\n`
    );

    const planTimer = new OperationTimer('plan-processing', {
      userId: user.id,
      period: `${format(calculationDate, "yyyy-MM")}`,
      numPlans: plans.length,
      numDeals: periodDeals?.length || 0
    });
    const planResults = await Promise.all(plans.map(async (plan) => {
      const commissions = await calculateCommissionsForPlan({
        calculationDate,
        dateRange,
        company,
        user,
        plan,
        isProjected,
        logs,
        isDev
      });
      return commissions || [];
    }));
    logOperation(planTimer.end(), logs, isDev);

    const aggregationTimer = new OperationTimer('commission-aggregation', {
      userId: user.id,
      period: `${format(calculationDate, "yyyy-MM")}`,
      numResults: planResults.length
    });
    planResults.forEach(commissions => {
      if (commissions && commissions.length > 0) {
        allCommissions.push(...commissions);
      }
    });
    logOperation(aggregationTimer.end(), logs, isDev);
    
    logOperation(periodTimer.end(), logs, isDev);
    calculationDate.setMonth(calculationDate.getMonth() + 1);
  }
  
  logs.push(
    "=".repeat(100),
    `Results for ${getFullName(user)} (${user.email} - ${user.id})`,
    `Period: ${format(dateRange?.from || new Date(), "MMM d, yyyy")} to ${format(dateRange?.to || new Date(), "MMM d, yyyy")}`,
    `Total commission: ${getCurrencyFormatted(getCommissionsValue(allCommissions), company?.default_currency?.code)}`,
    "=".repeat(100)
  );
  
  const commissionsByMonth = allCommissions.reduce((acc, commission) => {
    const month = format(new Date(commission.year, commission.month - 1), "MMMM yyyy", { locale: enUS });
    acc[month] = (acc[month] || 0) + (commission.amount || 0);
    return acc;
  }, {} as Record<string, number>);

  logs.push(`\nBreakdown by month:`);
  Object.entries(commissionsByMonth).forEach(([month, amount]) => {
    logs.push(`  ${month}: ${getCurrencyFormatted(amount, company?.default_currency?.code)}`);
  });

  logOperation(userTimer.end(), logs, isDev);
  return allCommissions;
};

export const getCommissionsValue = (commissions: NestedCommission[]) => {
  if (!commissions || commissions?.length === 0) return 0;
  return commissions?.reduce((acc, commission) => acc + commission?.amount, 0);
};

export const getCommissionForDateRange = (
  commissions: NestedCommission[],
  dateRange?: DateRange,
  filterOnStatuses?: CommissionStatus[]
) => {
  const { from, to } = dateRange || {};
  if (!from || !to) return [];
  
  const startDate = startOfDay(from);
  const endDate = endOfDay(to);
  
  const statusSet = filterOnStatuses ? new Set(filterOnStatuses) : null;
  
  return commissions?.filter((commission) => {
    if (statusSet && commission.status && !statusSet.has(commission.status)) {
      return false;
    }
    
    const commissionDate = new Date(commission.year, commission.month - 1);
    return commissionDate >= startDate && commissionDate <= endDate;
  });
};

export const getTotalCommissionOfUsers = (users: CommissionUser[]) => {
  return users.reduce((acc, user) => acc + user.value, 0);
};

export const getMonthName = (commission: NestedCommission) => {
  const { year, month } = commission;
  const date = new Date(year, month - 1);
  return format(date, "MMMM", { locale: enUS });
};

export const getUsersForScope = (
  company: SignedInCompany,
  scope?: Scope | null
) => {
  const { users, teams } = company || {};
  if (!scope) return users;
  if (scope.type === "user") {
    return users.filter(
      (user) => user.id === scope.value
    );
  }
  if (scope.type === "team") {
    const userIds = getUserIdsForTeam(teams, Number(scope.value));
    return users.filter((user) => userIds.includes(user.id));
  }
  return users;
};

export const getPaidCommissions = (commissions: NestedCommission[]) => {
  return commissions.filter((commission) => commission.status === "paid");
};

export const getKeyDeals = (
  scopedUsers: NestedUser[],
  groupedCommissions: GroupedCommissions,
  limit: number = 3
): KeyDeal[] => {
  const userMap = new Map(scopedUsers?.map(user => [user.id, user]));
  
  const keyDeals: KeyDeal[] = [];
  
  for (const [dealName, commissions] of Object.entries(groupedCommissions)) {
    const firstCommission = commissions?.[0];
    if (!firstCommission) continue;
    
    const deal = firstCommission.deal;
    if (!deal || deal.is_won || deal.is_lost) continue;
    
    const owner = userMap.get(firstCommission.user_id);
    if (!owner) continue;
    
    const dealValue = getDealValueFromCommissions(commissions);
    const commissionValue = getCommissionsValue(commissions);
    
    keyDeals.push({
      dealName,
      dealValue,
      owner,
      commissionValue,
    });
    
    if (keyDeals.length >= limit * 2) {
      break;
    }
  }
  
  return keyDeals
    .sort((a, b) => b.dealValue - a.dealValue)
    .slice(0, limit);
};

export const getCommissionsByQuotaTargetKeyDeals = (
  commissions: NestedCommission[],
  limit: number = 3
): NestedCommission[] => {
  return commissions
    .filter((c) => !c.deal?.is_won)
    .sort((a, b) => b.amount - a.amount)
    .slice(0, limit);
};

export const consolidateCommissions = (
  scopedCommissions: NestedCommission[],
  persistedCommissions?: NestedCommission[]
) => {
  if (!persistedCommissions?.length) return scopedCommissions;
  
  const consolidatedMap = new Map<string, NestedCommission>();
  
  // First add all scoped commissions to the map
  for (const commission of scopedCommissions) {
    const key = generateUniqueCommissionsKey({
      year: commission.year,
      month: commission.month,
      planId: commission.plan_id,
      userId: commission.user_id,
      dealsKey: commission.deals_key
    });
    consolidatedMap.set(key, commission);
  }

  // Then add or override with persisted commissions
  for (const commission of persistedCommissions) {
    const key = generateUniqueCommissionsKey({
      year: commission.year,
      month: commission.month,
      planId: commission.plan_id,
      userId: commission.user_id,
      dealsKey: commission.deals_key
    });
    consolidatedMap.set(key, commission);
  }
  
  // Return all commissions from the map
  return Array.from(consolidatedMap.values());
};
