import { integrations } from "@/constants/integrations";
import {
  CommissionBasedOn,
  NestedPlan,
  NestedToken,
  RevelateDealType,
  TokenProvider,
} from "@revelate/types";
import { capitalizeFirstLetter } from "@revelate/utils";

export type CommissionBasisButton = {
  label: string;
  value: CommissionBasedOn;
  scope: string;
};

export type DealTypeButtons = {
  value: RevelateDealType;
  label: string;
};

export const dealTypesRadioButtons: DealTypeButtons[] = [
  {
    value: "new_business",
    label: "New",
  },
  {
    value: "existing_business",
    label: "Existing",
  },
];

export const getProviderLabel = (provider: TokenProvider) => {
  return (
    integrations?.find((integration) => integration.id === provider)?.name ||
    capitalizeFirstLetter(provider)
  );
};

export const getProviderIcon = (provider: TokenProvider) => {
  return integrations?.find((integration) => integration.id === provider)?.icon;
};

export const getDealProvidersMultiSelectOptions = (tokens?: NestedToken[]) => {
  // TODO: Make sure currently selected providers are returned here and displayed in multi select even if we remove a token
  return tokens && tokens.length > 0
    ? tokens?.map((token) => {
        return {
          label: getProviderLabel(token.provider),
          value: token.provider,
          icon: undefined,
        };
      })
    : [];
};

export const commissionBasisButtons: CommissionBasedOn[] = [
  "deal_value",
  "onboarding_value",
  "deal_value_above_target",
  "deal_value_below_target",
  "fixed_value",
];

export const criteriaRadioButtons = [
  {
    label: "Always",
    value: "always",
  },
  {
    label: "Only if ALL criteria is met",
    value: "all_criteria_met",
  },
  {
    label: "Only if ANY criteria is met",
    value: "any_criteria_met",
  },
];

export const getCommissionDealTypeLabels = (
  deal_types?: RevelateDealType[]
) => {
  const dealTypes = dealTypesRadioButtons
    ?.filter((item) => deal_types?.includes(item.value))
    ?.map((item) => item.label.toLowerCase());
  return dealTypes.length === dealTypesRadioButtons.length
    ? "all deals"
    : `${dealTypes?.join(" and ")} business`;
};

export const getCommissionBasisLabelForValue = (
  plan: NestedPlan | null,
  b: string
) => {
  const dealTypeLabels = getCommissionDealTypeLabels(plan?.deal_types);
  switch (b) {
    case "deal_value":
      return `${capitalizeFirstLetter(dealTypeLabels)} of the ${
        plan?.time_period
      }`;
    case "onboarding_value":
      return `Onboarding fees from ${dealTypeLabels.toLowerCase()} of the ${
        plan?.time_period
      }`;
    case "deal_value_above_target":
      return `Closed deal value ABOVE sales target of the ${plan?.time_period}`;
    case "deal_value_below_target":
      return `Closed deal value BELOW sales target of the ${plan?.time_period}`;
    case "fixed_value":
      return `Fixed value for the entire ${plan?.time_period}`;
    default:
      return b;
  }
};

export const getCommissionBasisRadioButtons = (
  plan?: NestedPlan | null
): CommissionBasisButton[] => {
  return commissionBasisButtons.map((b) => {
    return {
      value: b,
      label: plan ? getCommissionBasisLabelForValue(plan, b) : b,
      scope:
        b === "fixed_value" ||
        b === "deal_value_above_target" ||
        b === "deal_value_below_target"
          ? `Reported once per ${plan?.time_period}` ||
            "Reported once for entire period"
          : "Reported deal per deal",
    };
  });
};

export const getCommissionBasisLabel = (
  plan: NestedPlan | null,
  commission_based_on_value: CommissionBasedOn
) => {
  const basisLabels = getCommissionBasisRadioButtons(plan);
  return basisLabels.find((item) => item.value === commission_based_on_value)
    ?.label;
};
