import { Button } from "@/components/ui/button";
import { ConfirmDeletionDialog } from "@/components/ConfirmDeletionDialog";
import {
  getApproved,
  getPaid,
  getUnapproved,
  isApproved,
  isUnapproved,
} from "@/lib/app";
import { getCommissionsValue } from "@revelate/calc";
import { Check, Download, PiggyBank, Trash2Icon } from "lucide-react";
import { useAppStore } from "@/stores";
import {
  CommissionStatus,
  NestedCommission,
  NestedUser,
} from "@revelate/types";
import { Loader } from "@/components/Loader";
import { getAPI, getCalcUrl } from "@/lib/api";
import { format } from "date-fns";
import { useCommissionsStream } from "@/hooks/useCommissionsStream";
import { useState } from "react";

interface ApproveButtonProps {
  commissions: NestedCommission[];
  updateCommissions: (
    previousStatus: CommissionStatus,
    newStatus: CommissionStatus,
    filterByCommission?: NestedCommission
  ) => Promise<void>;
  scopedUsers: NestedUser[];
  loading?: boolean;
}

export const ApproveButton = ({
  commissions,
  updateCommissions,
  scopedUsers,
  loading = false,
}: ApproveButtonProps) => {
  const { currentUser, dateRange } = useAppStore((state) => state);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadLogs = async () => {
    if (!currentUser || !currentUser?.company_id || !dateRange) return;
    setIsDownloading(true);
    try {
      const calcUrl = getCalcUrl(
        currentUser,
        dateRange,
        scopedUsers.map((user) => user.id),
        false,
        true
      );
      if (!calcUrl) return;

      const { logs } = await getAPI(calcUrl);

      if (!logs) return;
      // Create and download the text file
      const blob = new Blob([logs.join("\n")], {
        type: "text/plain;charset=utf-8",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `commission-logs-${dateRange?.from ? format(dateRange?.from, "yyyy-MM-dd") : ""}-to-${dateRange?.to ? format(dateRange?.to, "yyyy-MM-dd") : ""}.txt`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className="flex gap-2">
      {currentUser?.role === "super_admin" || currentUser?.role === "admin" ? (
        <>
          <Button
            onClick={handleDownloadLogs}
            size="lg"
            variant="outline"
            disabled={loading || isDownloading}
          >
            {isDownloading ? (
              <Loader className="h-5 w-5 mr-2" />
            ) : (
              <Download className="h-5 w-5 mr-2" />
            )}
            Download logs
          </Button>

          {isUnapproved(commissions) ? (
            <Button
              onClick={() => updateCommissions("unapproved", "approved")}
              size="lg"
              variant="default"
              className="bg-[#6B6397]"
              disabled={loading}
            >
              {loading ? (
                <Loader size={20} stroke="white" className="mr-2" />
              ) : (
                <Check className="h-5 w-5 mr-2" />
              )}
              Approve all ({getUnapproved(commissions).length})
            </Button>
          ) : isApproved(commissions) ? (
            <Button
              onClick={() => updateCommissions("approved", "paid")}
              size="lg"
              variant="default"
              className="bg-[#6B6397]"
            >
              <PiggyBank className="h-5 w-5 mr-2" />
              Mark as paid ({getApproved(commissions).length})
            </Button>
          ) : getCommissionsValue(commissions) > 0 ? (
            <ConfirmDeletionDialog
              key={`delete-commissions`}
              title={`Are you sure you want to revert all commissions for this period?`}
              description="All commissions for this period will be set to the default state."
              deleteId={"all"}
              deleteOperation={async () => {
                updateCommissions("paid", "unapproved");
              }}
            >
              <Button size="lg" variant="outline">
                <Trash2Icon className="h-5 w-5 mr-2" />
                Undo ({getPaid(commissions).length})
              </Button>
            </ConfirmDeletionDialog>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
