import { supabaseClient } from "./client";

export const getUniquePipelines = async (companyId: number) => {
  const { data, error } = await supabaseClient
    .from("deals")
    .select("pipeline")
    .eq("company_id", companyId)
    .not("pipeline", "is", null)
    .order("pipeline", { ascending: true });

  if (error) {
    console.error("Error fetching pipelines", error);
    throw error;
  }

  // Get unique pipeline values
  const uniquePipelines = [...new Set(data.map((deal) => deal.pipeline))];
  return uniquePipelines;
};
