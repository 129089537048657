import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { TableType, get, getAll, updateUserCurrency } from "@/lib/supabase";
import { NestedCurrency, UserWithDeals } from "@revelate/types";
import { useAppStore } from "@/stores";
import useSWR from "swr";
import { KeyedMutator } from "swr";

export function CurrencySelect({
  user,
  mutate,
}: {
  user: UserWithDeals;
  mutate: KeyedMutator<UserWithDeals[]>;
}) {
  const currentUser = useAppStore((state) => state.currentUser);

  const {
    data: company,
    error: companyError,
    isLoading: companyIsLoading,
    mutate: mutateCompany,
  } = useSWR(
    [
      "companies" as TableType,
      currentUser ? currentUser?.company_id?.toString() : null,
    ],
    ([table, companyId]) => get(table, companyId),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const {
    data: currencies,
    error,
    isLoading,
  } = useSWR(
    [
      "currencies" as TableType,
      currentUser ? currentUser?.company_id?.toString() : null,
    ],
    ([table, companyId]) => getAll(table, companyId),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const handleSelect = async (currencyId: string) => {
    const updatedUser = await updateUserCurrency(user.id, parseInt(currencyId));
    if (updatedUser) {
      mutate((users) => {
        if (!users) return users;
        return users.map((u) => (u.id === updatedUser.id ? updatedUser : u));
      }, false);
    }
  };

  const defaultCurrency = currencies?.find(
    (currency) => currency.id === company?.default_currency_id
  );

  if (isLoading || !currencies) return null;
  if (error) return null;

  return (
    <Select
      value={user.currency_id?.toString() || ""}
      onValueChange={handleSelect}
      disabled={
        currentUser?.role !== "super_admin" && currentUser?.role !== "admin"
      }
    >
      <SelectTrigger className="w-[120px]">
        <SelectValue placeholder={defaultCurrency?.code || "Default"} />
      </SelectTrigger>
      <SelectContent>
        {currencies.map((currency: NestedCurrency) => (
          <SelectItem key={currency.id} value={currency.id.toString()}>
            {currency.code}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
