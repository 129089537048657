import { LogInIcon } from "lucide-react";

import { Badge } from "@/components/ui/badge";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useAppStore } from "@/stores";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

import {
  capitalizeFirstLetter,
  getFullName,
  getUserInitials,
  sortByStringKey,
} from "@revelate/utils";
import useSWR from "swr";
import { TableType, getAll } from "@/lib/supabase";
import { Loader } from "@/components/Loader";
import { LoadingError } from "@/components/LoadingError";
import { ConfirmDeletionDialog } from "@/components/ConfirmDeletionDialog";
import { EmployeeCombobox } from "./components/employee-combobox";
import { CurrencySelect } from "./components/currency-select";
import { useAPI } from "@/hooks/useAPI";
import { UserWithDeals } from "@revelate/types";
import { ErrorWithRetry } from "@/components/ErrorWithRetry";

export function EmployeesPage() {
  const appState = useAppStore((state) => state);
  const { scope, currentUser } = appState;

  const {
    data: users,
    error,
    isLoading,
    mutate,
  } = useSWR(
    [
      "users" as TableType,
      currentUser ? currentUser?.company_id?.toString() : null,
    ],
    ([table, companyId]): Promise<UserWithDeals[]> => getAll(table, companyId),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const { getMagicLink } = useAPI();

  if (isLoading) return <Loader />;
  if (error)
    return (
      <ErrorWithRetry
        error={error.message}
        onRetry={() => window.location.reload()}
      />
    );

  return (
    <main className="grid flex-1 items-start gap-4 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-5">
      <div className="auto-rows-max items-start gap-4 md:gap-8 lg:col-span-5">
        <Card x-chunk="dashboard-05-chunk-3">
          <CardHeader className="px-7">
            <CardTitle>Users</CardTitle>
            <CardDescription>Manage users in your account.</CardDescription>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead></TableHead>
                  <TableHead>Name</TableHead>
                  <TableHead className="sm:table-cell">Role</TableHead>

                  <TableHead className="sm:table-cell">Currency</TableHead>

                  {/* <TableHead className="text-center hidden sm:table-cell">
                    Deals
                  </TableHead>
                  <TableHead className="text-center hidden sm:table-cell">
                    Individual Plans
                  </TableHead> */}

                  <TableHead />
                  <TableHead />
                </TableRow>
              </TableHeader>
              <TableBody>
                {users
                  ?.sort(sortByStringKey("first_name"))
                  ?.map((user, index) => {
                    return (
                      <TableRow
                        className={user.id === scope?.value ? "bg-accent" : ""}
                        key={index}
                      >
                        <TableCell>
                          <Avatar className="h-9 w-9">
                            <AvatarImage
                              src={user.avatar_url || ""}
                              alt="Avatar"
                            />
                            <AvatarFallback>
                              {getUserInitials(user)}
                            </AvatarFallback>
                          </Avatar>
                        </TableCell>
                        <TableCell>
                          <div className="font-medium">{getFullName(user)}</div>
                          <div className="hidden text-sm text-muted-foreground md:inline">
                            {user.email}
                          </div>
                        </TableCell>
                        <TableCell className="sm:table-cell">
                          {capitalizeFirstLetter(user?.role)}
                        </TableCell>
                        <TableCell className="sm:table-cell">
                          <CurrencySelect user={user} mutate={mutate} />
                        </TableCell>
                        {/* <TableCell className="hidden text-center sm:table-cell">
                          <Badge className="text-xs" variant="secondary">
                            {user?.deals?.length}
                          </Badge>
                        </TableCell>
                        <TableCell className="hidden text-center sm:table-cell">
                          <Badge className="text-xs" variant="secondary">
                            {user?.plans?.length}
                          </Badge>
                        </TableCell> */}

                        <TableCell>
                          <div className="flex items-center gap-3">
                            {/* <div className="ml-2 font-medium flex gap-1">
                              <Button
                                onClick={() =>
                                  setLocation(
                                    getRoute("employees", "user", user.id)
                                  )
                                }
                                variant={
                                  user.id === scope?.value
                                    ? "outline"
                                    : "outline"
                                }
                                size="icon"
                              >
                                <ChevronRight className="h-4 w-4" />
                              </Button>
                            </div> */}

                            {(currentUser?.role === "super_admin" ||
                              currentUser?.role === "admin") && (
                              <EmployeeCombobox
                                user={user}
                                employees={users}
                                mutate={mutate}
                              />
                            )}

                            {/* <ConfirmDeletionDialog
                                key={index}
                                deleteId={user.id.toString()}
                                deleteOperation={async (id) => {
                                  console.log("delete user");
                                }}
                              >
                                <Button size="icon" variant="outline">
                                  <Trash className="h-4 w-4" />
                                </Button>
                              </ConfirmDeletionDialog> */}
                          </div>
                        </TableCell>

                        {currentUser?.id !== user?.id && (
                          <TableCell className="text-center 2xl:table-cell">
                            <ConfirmDeletionDialog
                              key={`sign-in-as-${user.id}`}
                              title={`Are you sure you want to sign in as ${user.first_name}?`}
                              description="You will be signed out of your own account and need to sign in again."
                              deleteId={""}
                              deleteOperation={async () => {
                                const response = await getMagicLink(user.email);
                                const { magicLink } = response || {};
                                window.open(magicLink, "_self");
                              }}
                            >
                              <Button
                                size="icon"
                                className="bg-[#6B6397]"
                                variant="default"
                              >
                                <LogInIcon className="h-5 w-5" />
                              </Button>
                            </ConfirmDeletionDialog>
                          </TableCell>
                        )}
                        {/*
                      <TableCell className="hidden sm:table-cell">
                        <Badge
                          className="text-xs"
                          variant={getBadgeVariant(userCommissions)}
                        >
                          {capitalizeFirstLetter(
                            getBadgeVariant(userCommissions)
                          )}
                        </Badge>
                      </TableCell>
                      <TableCell className="text-right hidden sm:table-cell">
                        {getCurrencyFormatted(
                          getCommissionsValue(userCommissions)
                        )}
                      </TableCell>

                      <TableCell className="text-right hidden 2xl:table-cell">
                        {getCurrencyFormatted(
                          getCommissionsValue(getApproved(userCommissions)) +
                            getCommissionsValue(getPaid(userCommissions))
                        )}
                      </TableCell>
                      <TableCell className="text-right hidden 2xl:table-cell">
                        {getCurrencyFormatted(
                          getCommissionsValue(getPaid(userCommissions))
                        )}
                      </TableCell>

                      <TableCell>
                        <div className="ml-2 font-medium flex gap-1">
                          <Button
                            disabled={
                              getCommissionsValue(userCommissions) === 0
                            }
                            onClick={() => setLocation(getRoute("reports", "user", user.id))}
                            variant={
                              user.id === scope?.value ? "outline" : "outline"
                            }
                            size="icon"
                          >
                            <ChevronRight className="h-4 w-4" />
                          </Button>
                        </div>
                      </TableCell> */}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
      {/* <div className="md:col-span-1 lg:col-span-2">
        <ReportCard
        employees={getUsersList(allUsers)}
        timePeriod={getTimePeriodFromDateRange(dateRange)}
        ids={[]}
        mutateList={mutateList}
        />
      </div> */}
    </main>
  );
}
