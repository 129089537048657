import { Link, useLocation } from "wouter";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Checkbox } from "@/components/ui/checkbox";
import { useForm } from "react-hook-form";
import {
  Database,
  NestedPlan,
  NestedToken,
  TokenProvider,
} from "@revelate/types";
import { upsertPlan } from "@/lib/supabase";
import type { KeyedMutator } from "swr";
import { useAppStore } from "@/stores";
import { getRoute } from "@/lib/routes";
import {
  dealTypesRadioButtons,
  getDealProvidersMultiSelectOptions,
} from "@/lib/app";
import { capitalizeFirstLetter } from "@revelate/utils";
import { useJune } from "@/hooks/useJune";
import { MultiSelect } from "@/components/ui/multi-select";

const profileFormSchema = z.object({
  name: z
    .string()
    .min(2, {
      message: "Name must be at least 2 characters.",
    })
    .max(40, {
      message: "Name must not be longer than 40 characters.",
    }),
  time_period: z.string({
    required_error: "Please select a time period.",
  }),
  deal_role: z.enum(["owner", "collaborator", "management", "all_deals"]),
  status: z.enum(["draft", "published", "archived"]),
  providers: z.array(z.string()).refine((value) => value.some((item) => item), {
    message: "You have to select at least one item.",
  }),
  deal_types: z
    .array(z.string())
    .refine((value) => value.some((item) => item), {
      message: "You have to select at least one item.",
    }),
});

type ProfileFormValues = z.infer<typeof profileFormSchema>;

export const BasicsForm = ({
  plan: editPlan,
  tokens,
  mutate,
}: {
  plan?: NestedPlan;
  tokens?: NestedToken[];
  mutate: KeyedMutator<NestedPlan | null>;
}) => {
  const [, setLocation] = useLocation();
  const currentUser = useAppStore((state) => state.currentUser);
  const analytics = useJune();

  async function onSubmit(data: ProfileFormValues) {
    if (!currentUser || !currentUser.company_id) return;
    const planData: Database["public"]["Tables"]["plans"]["Insert"] = {
      id: editPlan?.id,
      company_id: currentUser.company_id, // Required
      name: data.name,
      time_period: data.time_period,
      deal_types: data.deal_types,
      providers: data.providers as TokenProvider[],
      deal_role: data.deal_role,
      status: data.status,
    };
    const plan = await upsertPlan(planData);
    if (!plan) return;
    // Track plan changes
    analytics?.track(editPlan ? "Plan edited" : "Plan created", {
      id: plan.id,
      name: plan.name,
      time_period: plan.time_period,
      deal_types: plan.deal_types,
    });
    mutate({ ...plan });
    setLocation(getRoute("plans", "plan", plan.id.toString(), "accelerators"));
  }

  // // This can come from your database or API.
  const defaultValues: Partial<ProfileFormValues> = {
    name: editPlan?.name || "",
    time_period: editPlan?.time_period || "month",
    deal_types: editPlan?.deal_types || ["new_business", "existing_business"],
    providers: editPlan?.providers || [],
    deal_role: editPlan?.deal_role || "owner",
    status: editPlan?.status || "draft",
  };

  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(profileFormSchema),
    defaultValues,
    mode: "onChange",
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input
                  placeholder={`${capitalizeFirstLetter(
                    form.watch("time_period")
                  )}ly Sales Bonus`}
                  {...field}
                />
              </FormControl>
              <FormDescription className="text-xs">
                Give a meaningful name to the plan visible for your employees.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="time_period"
          render={({ field }) => (
            <FormItem className="space-y-3">
              <FormLabel>Payment Frequency</FormLabel>
              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="flex flex-col space-y-1"
                >
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="month" />
                    </FormControl>
                    <FormLabel className="font-normal">Monthly</FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="quarter" />
                    </FormControl>
                    <FormLabel className="font-normal">Quarterly</FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="year" />
                    </FormControl>
                    <FormLabel className="font-normal">Yearly</FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormDescription className="text-xs">
                How often do you provide compensation?
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="deal_types"
          render={() => (
            <FormItem>
              <FormLabel>Deal Type(s)</FormLabel>
              {dealTypesRadioButtons.map((item) => (
                <FormField
                  key={item.value}
                  control={form.control}
                  name="deal_types"
                  render={({ field }) => {
                    return (
                      <FormItem
                        key={item.value}
                        className="flex flex-row items-start space-x-3 space-y-0"
                      >
                        <FormControl>
                          <Checkbox
                            checked={field.value?.includes(item.value)}
                            onCheckedChange={(checked) => {
                              return checked
                                ? field.onChange([...field.value, item.value])
                                : field.onChange(
                                    field.value?.filter(
                                      (value) => value !== item.value
                                    )
                                  );
                            }}
                          />
                        </FormControl>
                        <FormLabel className="font-normal">
                          {item.label} business
                        </FormLabel>
                      </FormItem>
                    );
                  }}
                />
              ))}
              <FormDescription className="text-xs">
                What type of deals do you compensate for?
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="providers"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Deal Providers</FormLabel>
              <FormControl>
                <MultiSelect
                  options={getDealProvidersMultiSelectOptions(tokens)}
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  placeholder="Select providers from list"
                  variant="inverted"
                  animation={0}
                  maxCount={3}
                  {...field}
                />
              </FormControl>
              <FormDescription className="text-xs">
                Where are the deals located? This list is based on providers
                connected to your account.
                <Button size={null} variant="link" className="pl-1 text-xs">
                  <Link href="/integrations">Add more providers here</Link>
                </Button>
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="deal_role"
          render={({ field }) => (
            <FormItem className="space-y-3">
              <FormLabel>Deal Role</FormLabel>

              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="flex flex-col space-y-1"
                >
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="owner" />
                    </FormControl>
                    <FormLabel className="font-normal">Deal Owner</FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="collaborator" />
                    </FormControl>
                    <FormLabel className="font-normal">Collaborator</FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="management" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      All Deals for {capitalizeFirstLetter(form.watch("time_period"))} (Regardless of Owner)
                    </FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormDescription className="text-xs">
                Which role do you want to compensate for? Use
                Collaborator for e.g. deal splits or All Deals for e.g. management bonus.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="status"
          render={({ field }) => (
            <FormItem className="space-y-3">
              <FormLabel>Status</FormLabel>

              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="flex flex-col space-y-1"
                >
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="draft" />
                    </FormControl>
                    <FormLabel className="font-normal">Draft</FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="published" />
                    </FormControl>
                    <FormLabel className="font-normal">Published</FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormDescription className="text-xs">
                Only for visual purposes ATM. In the future, draft plans will
                only be visible to you.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit" className="bg-[#6B6397]">
          Continue
        </Button>
      </form>
    </Form>
  );
};
