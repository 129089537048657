import DashboardPage from "@/dashboard/page";
import { Route, Switch } from "wouter";
import { ReportsPage } from "@/reports/page";
import { PlansPage } from "@/plans/page";
import { EditPlanPage } from "@/plans/edit/page";
import { NotFound } from "@/components/NotFound";
import { QuotasPage } from "@/quotas/page";
import { EmployeesPage } from "@/employees/page";
import { EditQuotaPage } from "@/quotas/edit/page";
import { CurrenciesPage } from "@/currencies/page";
import { DealsPage } from "@/deals/page";
import { IntegrationsPage } from "@/integrations/page";
import { Logout } from "./logout";
import { useAppStore } from "@/stores";
import { TeamsPage } from "@/teams/page";
import { EditTeamPage } from "@/teams/edit/page";

export const Routes = () => {
  const { currentUser } = useAppStore((state) => state);
  return (
    <>
      <Switch>
        {/* <Route path="/reports" component={ReportsPage} /> */}
        <Route path="/logout" component={Logout} />
        <Route path="/reports/:type?/:id?/*?" component={ReportsPage} />
        {(currentUser?.role === "super_admin" ||
          currentUser?.role === "admin") && (
          <>
            <Route path="/plans" component={PlansPage} />
            <Route path="/plans/plan/new" component={EditPlanPage} />
            <Route path="/plans/plan/:id?/*?" component={EditPlanPage} />
            <Route path="/quotas" component={QuotasPage} />
            <Route path="/quotas/quota/new" component={EditQuotaPage} />
            <Route path="/quotas/quota/:id?/*?" component={EditQuotaPage} />
            <Route path="/currencies" component={CurrenciesPage} />
            <Route path="/deals" component={DealsPage} />
            <Route path="/integrations" component={IntegrationsPage} />
            <Route path="/teams" component={TeamsPage} />
            <Route path="/teams/team/new" component={EditTeamPage} />
            <Route path="/teams/team/:id?/*?" component={EditTeamPage} />
            {/* <Route path="/currencies/currency/:id?/*?" component={CurrenciesPage} /> */}
          </>
        )}
        {currentUser?.role !== "employee" && (
          <>
            <Route path="/users/:type?/:id?/*?" component={EmployeesPage} />
          </>
        )}
        <Route path="/dashboard/:type?/:id?" component={DashboardPage} />
        <Route path="/" component={DashboardPage} />
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </>
  );
};
